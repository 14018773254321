<template>
  <div class="page search">
    <HeadBar
      class="search-headbar"
      title=""
      :isShowSinging="false"
      :isShowSetting="false"
      :isShowRight="true"
      :isSearch="true"
      ref="headBarRef"
      @search="handleOnSearch"
    />

    <div class="search-content">
      <div
        class="song-list song-list-padding"
        v-if="keyword"
        :class="shouldShowBanner && 'has-banner'"
      >
        <div class="tabs">
          <div
            class="tab"
            :class="{ active: curSearchTab === 'song' }"
            @click="handleSwitchSearchTab('song')"
          >
            歌曲
          </div>
          <div
            class="tab"
            :class="{ active: curSearchTab == 'singer' }"
            @click="handleSwitchSearchTab('singer')"
          >
            歌手
          </div>
        </div>
        <div v-if="curSearchTab === 'song'">
          <LoadMore
            class="song-list-content"
            v-if="resultData.song.length"
            @load-more="getSearchResult"
            safeAreaHeight="12.6991vw"
          >
            <div
              v-if="shouldShowBanner"
              class="search-banner"
              @click="handleClickBanner"
              v-log="{
                event_type: 'show',
                event_name: 30192,
                event_data: {
                  str1: '搜索结果页',
                  str2: '歌曲结果',
                  str3: '列表运营位展示',
                  str4: 'show',
                },
              }"
            ></div>
            <SongItem
              v-for="(songItem, index) in resultData.song"
              :key="index"
              :songItem="songItem"
              :log-from="{
                song_list_source: 2,
                fr2: '搜索页会员歌曲',
              }"
            ></SongItem>
          </LoadMore>
          <div v-else class="empty-wrapper">
            <div class="empty-block">
              <svg
                width="90"
                height="90"
                viewBox="0 0 90 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.4" clip-path="url(#clip0_346_155436)">
                  <circle
                    cx="62.1964"
                    cy="33.4815"
                    r="18.5557"
                    stroke="#1D1D1F"
                    stroke-width="4"
                  />
                  <path
                    d="M45.0664 38.9043L21.6423 68.7885L28.1023 75.2485L57.9865 51.8244"
                    stroke="#1D1D1F"
                    stroke-width="4"
                  />
                  <path
                    d="M18.8096 77.4078L20.1455 78.8963L20.1455 78.8963L18.8096 77.4078ZM9.2533 68.1959L10.7898 69.4763L9.2533 68.1959ZM15.6039 60.5749L17.1404 61.8552L17.1404 61.8552L15.6039 60.5749ZM12.9925 54.9995L12.9925 52.9995L12.9925 52.9995L12.9925 54.9995ZM12.75 52.9995C11.6454 52.9995 10.75 53.8949 10.75 54.9995C10.75 56.1041 11.6454 56.9995 12.75 56.9995L12.75 52.9995ZM9.79607 77.293L11.1694 75.8391L11.1694 75.8391L9.79607 77.293ZM15.2047 55.8179L13.9031 57.3364L15.2047 55.8179ZM24.6137 69.5115L17.4738 75.9194L20.1455 78.8963L27.2854 72.4884L24.6137 69.5115ZM10.7898 69.4763L17.1404 61.8552L14.0675 59.2945L7.71683 66.9156L10.7898 69.4763ZM12.9925 52.9995L12.75 52.9995L12.75 56.9995L12.9925 56.9995L12.9925 52.9995ZM11.1694 75.8391C9.38241 74.1512 9.21616 71.3647 10.7898 69.4763L7.71683 66.9156C4.79078 70.427 5.09992 75.6084 8.42274 78.747L11.1694 75.8391ZM17.4738 75.9194C15.6715 77.5369 12.9299 77.502 11.1694 75.8391L8.42274 78.747C11.6964 81.8391 16.7942 81.904 20.1455 78.8963L17.4738 75.9194ZM16.5063 54.2993C15.5277 53.4606 14.2814 52.9995 12.9925 52.9995L12.9925 56.9995C13.3265 56.9995 13.6495 57.119 13.9031 57.3364L16.5063 54.2993ZM17.1404 61.8552C19.0306 59.5869 18.7481 56.2209 16.5063 54.2993L13.9031 57.3364C14.4841 57.8344 14.5573 58.7067 14.0675 59.2945L17.1404 61.8552Z"
                    fill="#1D1D1F"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_346_155436">
                    <rect width="90" height="90" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>抱歉，暂无“{{ keyword }}”结果</p>
              <p class="empty-block-lack" v-if="isShowLackBtn">
                找不到想唱的歌？<span @click="handleLackBtn">缺歌反馈</span>
              </p>
              <p class="empty-block-lack" v-else>
                已收到您的反馈，小雷紧急安排中
              </p>
            </div>
            <GuessSonglist renderType="block" showAllData />
          </div>
          <p class="hint" v-if="isEmpty && resultData.song.length > 0">
            已加载全部
          </p>
        </div>
        <div v-else>
          <LoadMore
            class="singer-list"
            ref="loadMoreRef"
            v-if="resultData.singer.length"
            @load-more="getSearchResult"
            safeAreaHeight="12.6991vw"
          >
            <SingerItem
              v-for="(item, index) in resultData.singer"
              :key="index"
              :singer="item"
              @click="handleClickSinger(item)"
            ></SingerItem>
          </LoadMore>
          <div v-else class="empty-wrapper">
            <div class="empty-block">
              <svg
                width="90"
                height="90"
                viewBox="0 0 90 90"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.4" clip-path="url(#clip0_346_155436)">
                  <circle
                    cx="62.1964"
                    cy="33.4815"
                    r="18.5557"
                    stroke="#1D1D1F"
                    stroke-width="4"
                  />
                  <path
                    d="M45.0664 38.9043L21.6423 68.7885L28.1023 75.2485L57.9865 51.8244"
                    stroke="#1D1D1F"
                    stroke-width="4"
                  />
                  <path
                    d="M18.8096 77.4078L20.1455 78.8963L20.1455 78.8963L18.8096 77.4078ZM9.2533 68.1959L10.7898 69.4763L9.2533 68.1959ZM15.6039 60.5749L17.1404 61.8552L17.1404 61.8552L15.6039 60.5749ZM12.9925 54.9995L12.9925 52.9995L12.9925 52.9995L12.9925 54.9995ZM12.75 52.9995C11.6454 52.9995 10.75 53.8949 10.75 54.9995C10.75 56.1041 11.6454 56.9995 12.75 56.9995L12.75 52.9995ZM9.79607 77.293L11.1694 75.8391L11.1694 75.8391L9.79607 77.293ZM15.2047 55.8179L13.9031 57.3364L15.2047 55.8179ZM24.6137 69.5115L17.4738 75.9194L20.1455 78.8963L27.2854 72.4884L24.6137 69.5115ZM10.7898 69.4763L17.1404 61.8552L14.0675 59.2945L7.71683 66.9156L10.7898 69.4763ZM12.9925 52.9995L12.75 52.9995L12.75 56.9995L12.9925 56.9995L12.9925 52.9995ZM11.1694 75.8391C9.38241 74.1512 9.21616 71.3647 10.7898 69.4763L7.71683 66.9156C4.79078 70.427 5.09992 75.6084 8.42274 78.747L11.1694 75.8391ZM17.4738 75.9194C15.6715 77.5369 12.9299 77.502 11.1694 75.8391L8.42274 78.747C11.6964 81.8391 16.7942 81.904 20.1455 78.8963L17.4738 75.9194ZM16.5063 54.2993C15.5277 53.4606 14.2814 52.9995 12.9925 52.9995L12.9925 56.9995C13.3265 56.9995 13.6495 57.119 13.9031 57.3364L16.5063 54.2993ZM17.1404 61.8552C19.0306 59.5869 18.7481 56.2209 16.5063 54.2993L13.9031 57.3364C14.4841 57.8344 14.5573 58.7067 14.0675 59.2945L17.1404 61.8552Z"
                    fill="#1D1D1F"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_346_155436">
                    <rect width="90" height="90" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>抱歉，暂无“{{ keyword }}”结果</p>
            </div>
            <GuessSonglist renderType="block" showAllData />
          </div>
          <p class="hint" v-if="isEmpty && resultData.singer.length > 20">
            已加载全部
          </p>
        </div>
      </div>
      <div v-if="!keyword" class="init">
        <SearchHistory @clickWord="handleChangeInput" />
        <!-- <GuessSonglist renderType="block" showAllData /> -->

        <GuessSonglist showAllData />
      </div>
    </div>

    <!-- <div class="search-bottom">
      <img
        src="https://qncweb.ktvsky.com/20240910/vadd/f35d83caa9333332d728fd34a4f96bd4.png"
        alt=""
      />
    </div> -->

    <OperationBottomQr
      :isShow="isShowOperationBottomQr && resultData.song.length"
      from="search"
      :msg="operationBottomQrMsg"
    />

    <!-- 功能下线 -->
    <!-- <RecommendSong :isActive="isShowRecommend" from="search" @close="handleCloseRecommend" /> -->
  </div>
</template>

<script>
import GuessSonglist from '@/components/guess-song/songlist.vue'
import OperationBottomQr from '@/components/operation/qr.vue'
import SearchHistory from '@/components/search-history/index.vue'
import SingerItem from '@/components/singer-item/index.vue'
import SongItem from '@/components/song-item/index.vue'
import { useShareBrowserSize } from '@/composables/sharedComposable'
import useVip from '@/composables/useVip'
import { vipLogFrom } from '@/constants/index'
import { sendLog } from '@/directives/v-log/log'
import logParams from '@/log'
import { search } from '@/service/search'
import { feedback } from '@/service/song'
import {
  // getCloseSearchRecommendTag,
  getReduceSearchRecommendSetting,
  setSearchCache,
} from '@/utils/historyCache'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { computed, onActivated, onMounted, ref, watch } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
  name: 'Search',
  components: {
    SongItem,
    SingerItem,
    GuessSonglist,
    SearchHistory,
    // HeadBar,
    // PureSearch,
    OperationBottomQr,
    // RecommendSong,
  },
  activated() {
    const store = useStore()
    const { isUsed, position } = store.state.pageCacheData.search
    if (!isUsed) {
      this.handleInitData()
      this.handleClearInput()
    } else {
      if (this.$refs.loadMoreRef) {
        this.$refs.loadMoreRef.root.scrollTop = position
      }
      this.handleUpdateCachePosition(false, 0)
    }
    this.handleFocusInput() //只要进入就锁定

    if (this.curSearchTab === 'singer') {
      sendLog({
        event_type: '10000~50000',
        event_name: 30162,
        event_data: {
          str1: '歌手',
          str2: '搜索',
          str3: '搜索框点击',
          str4: 'click',
        },
      })
    } else {
      sendLog({
        event_type: '10000~50000',
        event_name: 10019,
        event_data: {
          str1: '搜索页',
          str2: '搜索页',
          str3: '进入搜索页',
          str4: 'show',
        },
      })
    }
  },
  methods: {
    handleClearInput() {
      if (this.$refs.headBarRef.pureSearchRef)
        this.$refs.headBarRef.pureSearchRef.handleClearInput()
    },
    handleFocusInput() {
      if (this.$refs.headBarRef.pureSearchRef)
        this.$refs.headBarRef.pureSearchRef.handleFocusInput()
    },
    handleChangeInput(e) {
      console.log('search word')
      sendLog({
        event_type: '10000~50000',
        event_name: 10020,
        event_data: {
          str1: '搜索页',
          str2: '搜索历史',
          str3: '点击歌曲',
          str4: 'click',
        },
      })
      this.isShowLackBtn = true
      this.handleChangeKeyword(e)
      if (this.$refs.headBarRef.pureSearchRef)
        this.$refs.headBarRef.pureSearchRef.handleChangeKeyword(e)
    },
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const { showVipQrcode } = useVip()
    const { browserType } = useShareBrowserSize()
    // const { showRecommendConfirm } = useRecommend()
    const isShowRecommend = ref(false)

    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const searchCacheList = computed(() => store.state.search.searchCache)
    const shouldShowBanner = computed(() => {
      const isSongTab = curSearchTab.value === 'song'
      const vipSongsCount = resultData.value.song.filter(
        (item) => item.is_vip
      ).length

      return isSongTab && vipSongsCount > 3
    })
    const orderedListNumber = computed(() => {
      return store.state.orderedList.length
    })

    const isShowOperationBottomQr = ref(false)
    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const operationBottomQrMsg = computed(() => {
      let msg = ''
      if (keyword.value) {
        // after search
        msg =
          keyword.value.length > 3
            ? `${keyword.value.slice(0, 3)}...`
            : keyword.value
        return `“${msg}”全部好歌 立即开唱`
      }

      return 'VIP会员无限欢唱'
    })

    const isShowLackBtn = ref(true)
    let curSearchTab = ref('song')
    let loadMoreRef = ref(null)
    let keyword = ref('')
    let isEmpty = ref(false)
    let resultData = ref({
      singer: [],
      song: [],
    })
    let paginationNumberRecord = {
      singer: 1,
      song: 1,
    }
    let isRequest = false
    // let isShowVipActEnter = ref(false)
    const headBarRef = ref(null)

    const handleInitData = () => {
      const params = route.query?.defaultTab
      if (params) {
        curSearchTab.value = params
      }
      console.log(
        'handleInitData',
        'params',
        params,
        'curSearchTab.value',
        curSearchTab.value
      )

      keyword.value = ''
      resultData.value.singer = []
      resultData.value.song = []
    }

    const handleSwitchSearchTab = (tab) => {
      curSearchTab.value = tab
      // TODO
      if (!resultData.value[tab].length) {
        searchHandler[tab].call()
      }
    }
    const handleOnSearch = (k) => {
      if (!k.trim()) {
        showVipQrcode({
          log: '搜索页-搜索栏-空词搜索弹窗',
          isLogin: isLogin.value,
          fr: vipLogFrom.get('搜索页-搜索栏-空词搜索弹窗'),
        })
        sendLog({
          event_type: 'show',
          event_name: 30191,
          event_data: {
            str1: '搜索页',
            str2: '搜索栏',
            str3: '空词搜索弹窗',
            str4: 'show',
            str5: isLogin.value ? '已登录' : '未登录',
          },
        })
        return
      }
      keyword.value = k
      isShowOperationBottomQr.value = true
      isShowLackBtn.value = true
    }

    const handleClickSinger = ({ singer, singerhead, singerid }) => {
      // console.log('handleClickSinger:', singer, singerhead, singerid)
      router.push({
        name: 'songList',
        query: {
          name: singer,
          image: singerhead,
          singerid,
        },
      })
    }

    const getSearchReportStatus = (res) => {
      if (res.errcode !== 200) {
        return 12
      }
      return res.singer.length || res.song.length ? 10 : 11
    }

    // 优先展示搜索的vip歌曲
    // const toSongvipSort = (arr) => {
    //   let arr1 = [],
    //     arr2 = []
    //   for (let i in arr) {
    //     if (arr[i].is_vip) {
    //       arr1.push(arr[i])
    //     } else {
    //       arr2.push(arr[i])
    //     }
    //   }
    //   return arr1.concat(arr2)
    // }

    const searchHandler = {
      singer: async () => {
        const bussinessResponseData = await search(
          keyword.value,
          paginationNumberRecord.singer,
          'singer'
        )
        // 搜索上报 10:搜索到结果，11:未搜索到结果，12:接口异常
        sendLog({
          event_type: 'click',
          event_name: 122,
          event_data: {
            key_words: keyword.value,
            status: getSearchReportStatus(bussinessResponseData),
          },
        })
        if (bussinessResponseData.singer.length) {
          resultData.value.singer = resultData.value.singer.concat(
            bussinessResponseData.singer
          )
          paginationNumberRecord.singer++
        }
        isRequest = false
        sendLog({
          event_type: '10000~50000',
          event_name: 10021,
          event_data: {
            str1: '搜索页',
            str2: '搜索栏',
            str3: '点击搜索',
            str4: 'click',
          },
        })
      },
      song: async () => {
        const bussinessResponseData = await search(
          keyword.value,
          paginationNumberRecord.song,
          'song'
        )
        // 搜索上报 10:搜索到结果，11:未搜索到结果，12:接口异常
        sendLog({
          event_type: 'click',
          event_name: 122,
          event_data: {
            key_words: keyword.value,
            status: getSearchReportStatus(bussinessResponseData),
          },
        })
        if (bussinessResponseData.song.length) {
          const songvipsort = bussinessResponseData.song // toSongvipSort(bussinessResponseData.song) - 转为服务端处理
          setSearchSongList(songvipsort)
          resultData.value.song = resultData.value.song.concat(songvipsort)
          paginationNumberRecord.song++
        }
        isRequest = false
        sendLog({
          event_type: '10000~50000',
          event_name: 10021,
          event_data: {
            str1: '搜索页',
            str2: '搜索栏',
            str3: '点击搜索',
            str4: 'click',
          },
        })
      },
    }

    const getSearchResult = async () => {
      if (isRequest) {
        return
      }
      isRequest = true
      searchHandler[curSearchTab.value].call()
    }

    const handleChangeKeyword = (e) => {
      if (e) {
        keyword.value = e
        isShowOperationBottomQr.value = true
      }
    }

    const setSearchCacheList = (k) => {
      // 存储搜索历史
      if (!searchCacheList.value.includes(k)) {
        let newSearchCacheList = [k, ...searchCacheList.value]
        newSearchCacheList = newSearchCacheList.slice(0, 10)
        store.dispatch('search/updateSearchCache', newSearchCacheList)
        setSearchCache(newSearchCacheList)
      }
    }

    const setSearchSongList = (list) => {
      // 搜索词条时 如果第一页中有一首歌名与搜索内容一致 则符合一键演唱功能
      if (!!keyword.value && !resultData.value.song.length) {
        list.some((v) => {
          const music_name = get(v, 'music_name', '').split('(HD)')[0]
          if (music_name === keyword.value) {
            store.dispatch('search/addSearchSong', {
              ...v,
              searchname: keyword.value,
            })
            return true
          }
          return false
        })
      }
    }

    // const handleCloseRecommend = (isHasControl) => {
    //   console.log(isHasControl, 'handleCloseRecommend isHasControl')
    //   isShowRecommend.value = false
    //   if (!isHasControl) {
    //     // open recommend song modal logic
    //     const closeTag = getCloseSearchRecommendTag()
    //     if (closeTag > 2) return
    //     if (closeTag === 2) {
    //       //3次无操作
    //       showRecommendConfirm()
    //       const closeLogParams = logParams.get(30304)
    //       closeLogParams.event_data.str1 = '搜索页'
    //       sendLog(closeLogParams)
    //     }
    //     setCloseSearchRecommendTag(closeTag + 1)
    //   }
    // }

    const handleUpdateCachePosition = (u, v) => {
      store.commit('UPDATE_PAGE_CACHEDATA', {
        data: {
          isUsed: u,
          position: v,
        },
        type: 'search',
      })
    }

    const handleClickBanner = () => {
      showVipQrcode({
        log: '搜索结果页-歌曲结果-列表运营位弹窗',
        isLogin: isLogin.value,
        fr: vipLogFrom.get('搜索结果页-歌曲结果-列表运营位弹窗'),
      })
      sendLog({
        event_type: 'show',
        event_name: 30193,
        event_data: {
          str1: '搜索结果页',
          str2: '歌曲结果',
          str3: '列表运营位弹窗',
          str4: 'show',
          str5: isLogin.value ? '已登录' : '未登录',
        },
      })
    }

    watch(keyword, (k) => {
      if (k) {
        resultData.value = {
          singer: [],
          song: [],
        }
        paginationNumberRecord = {
          singer: 1,
          song: 1,
        }
        getSearchResult()
        setSearchCacheList(k)
      }
    })

    /**
     * 1 - 保持推荐 3
     * 2 - 减少推荐频率 0
     * 3 - 从不推荐
     */
    const startRecommend = () => {
      const setting = getReduceSearchRecommendSetting()
      if (setting === 3) return
      const show =
        setting === 1
          ? orderedListNumber.value < 4
          : orderedListNumber.value === 0
      if (show) {
        isShowRecommend.value = true
        sendLog(logParams.get(30300))
      }
    }

    const closeRecommend = () => {
      isShowRecommend.value = false
    }

    const handleHideOperationBottomQr = () => {
      isShowOperationBottomQr.value = false
    }

    onMounted(() => {
      // if (checkLandscapeOrPortrait() === 'landscape') {
      //   isShowVipActEnter.value = true
      // }
      // startRecommend();
    })

    onActivated(() => {
      console.log('onActivated')
      startRecommend()
      handleHideOperationBottomQr()
    })

    onBeforeRouteLeave((to, from, next) => {
      closeRecommend()
      if (to.name === 'songList') {
        const position = loadMoreRef.value
          ? loadMoreRef.value.root.scrollTop
          : 0
        handleUpdateCachePosition(true, position)
      } else {
        if (headBarRef.value.pureSearchRef)
          headBarRef.value.pureSearchRef.resetValue()
      }
      next()
    })

    const handleLackBtn = async () => {
      isShowLackBtn.value = false
      const res = await feedback({
        type: '缺歌反馈',
        description: keyword.value,
        _isvip: isVip.value ? 1 : 0,
      })
      if (res !== 200) {
        isShowLackBtn.value = true
        Toast('提交失败，请稍后再试')
      }
    }

    return {
      isLogin,
      isEmpty,
      curSearchTab,
      resultData,
      keyword,
      isShowOperationBottomQr,
      operationBottomQrMsg,
      loadMoreRef,
      // isShowRecommend,
      // isShowVipActEnter,
      handleSwitchSearchTab,
      handleOnSearch,
      handleClickSinger,
      handleChangeKeyword,
      setSearchCacheList,
      getSearchResult,
      handleInitData,
      handleUpdateCachePosition,
      shouldShowBanner,
      handleClickBanner,
      headBarRef,
      browserType,
      handleLackBtn,
      isShowLackBtn,
      // handleCloseRecommend,
    }
  },
}
</script>

<style lang="stylus" scoped>
.search
  // padding 150px 180px 0
  padding 150px 64px 0
  height 100vh
  overflow scroll
  display flex
  flex-direction column
  @media screen and (max-width 1200px) and (min-height 1200px)
    padding-right 64px
    padding-left 64px
  .search-bar
    background none
  .song-list-padding
    padding 0 !important
    height auto
  .song-list
    flex 1
    overflow hidden
    width 100% !important
    box-sizing border-box
    display flex
    flex-direction column
    ::-webkit-scrollbar
      display none
    &.has-banner
      .song-list-content
        margin-top 0px!important
    &-content
      margin-top 76px
      position relative
      padding-left 0px
      padding-right 0px
      padding-bottom 300px!important
      ::v-deep .song
        width 100%
        height 141px
        .song-item
          padding 0 20px
          .right
            margin-right 0
    .tabs
      width 100%
      height 100px
      margin-left 0px
      border-bottom 2px solid rgba(29, 29, 31, 0.1)
      display flex
      justify-content center
      align-items center
      .tab
        display flex
        align-items center
        justify-content center
        width 96px
        height 100px
        color rgba(29, 29, 31, 0.7)
        font-size var(--font-size-large)
        &:nth-child(1)
          margin-right 394px
      .active
        border-bottom 2px solid rgba(160, 74, 240, 1)
        color rgba(160, 74, 240, 1)
    .search-banner
      background url('https://qncweb.ktvsky.com/20240314/other/6f50d9c6a4389266b3e50c2129baa53d.png') no-repeat
      width 760px
      height auto
      background-size 100% auto
      margin-top 20px
      position relative
    .empty
      overflow-y scroll
    & > div:not(.tabs):not(.search-banner)
      flex 1
      overflow hidden
  .singer-list
    flex 1
    margin 0 auto
    text-align center
    display flex
    flex-wrap wrap
    box-sizing border-box
    display grid
    grid-template-columns repeat(6, 216px)
    // grid-template-columns repeat(4, 174px)
    justify-content space-between
    padding 48px 0px 150px 0px !important
    @media screen and (max-width 1200px) and (min-height 1200px)
      padding 40px 0 850px 0 !important
      grid-template-columns repeat(4, 200px)
      .singer-item
        width 200px
        height 246px
        margin-bottom 60px
        ::v-deep
          .singer-item-cover
            width 200px
            height 200px
            margin-bottom 20px
          p
            width 200px
            line-height 1
  .empty-wrapper
    overflow-y scroll
    height 100%
    padding-bottom 200px
  .empty-block
    margin 150px 0
    font-size 28px
    color rgba(29, 29, 31, 0.4)
    text-align center
    display flex
    flex-direction column
    align-items center
    justify-content center
    svg
      margin-bottom 40px
      width 90px
      height 90px
    @media screen and (max-width 1200px) and (min-height 1200px)
      margin 130px 0 130px
    &-lack
      color rgba(29, 29, 31, 1)
      margin-top 30px
      font-size 28px
      font-weight 400
      span
        display inline-block
        width 300px
        height 80px
        line-height 78px
        border-radius 40px
        background rgba(255, 255, 255, 1)
        margin-left 20px
        border 2px solid rgba(29, 29, 31, 0.1)
        color rgba(221, 153, 73, 1)

  .hint
    text-align center
    color #555555
  ::v-deep .search-history
    margin-top 24px
    overflow-y hidden
    &-content
      // height 200px
      height auto
      overflow-y scroll
    @media screen and (max-width 1200px) and (min-height 1200px)
      padding 0
  // ::v-deep .sec-gusse-sing
  //   .sec-gusse-sing-list
  //     grid-template-columns repeat(3, 498px)
  //     .song
  //       margin-bottom 32px
  //     @media screen and (max-width 1200px) and (min-height 1200px)
  //       grid-template-columns repeat(2, 520px)
  //       .song
  //         margin-bottom 0px
  //   .section-container-header-title
  //     color rgba(29, 29, 31, 0.6)
  //     font-size var(--font-size-32)

  ::v-deep .sec-gusse-sing
    .sec-gusse-sing-list
      width: 100%;
      border-radius: var(--border-radius-large);
      padding: 20px 0;
      gap: 0;
      grid-template-columns: repeat(3, 1fr);
      background: #fff;
      .song-item
        border-bottom none
    .section-container-header-title
      position relative
      &::after
        content ''
        position absolute
        top 10px
        left 152px
        width 32px
        height 32px
        background url('https://qncweb.ktvsky.com/20241204/vadd/f41ee097239c1a65d271847789d98f6c.png') no-repeat
        background-size 100% 100%
        background-position center

  .search-content
    display flex
    flex-direction column
    align-items center
    width 100%
    height calc(100vh - 150px)
    padding 0 0px
    @media screen and (max-width 1200px) and (min-height 1200px)
      padding 0 calc(92px - 64px)
      ::v-deep .song-item
        height 166px
        .name-txt
          font-size 36px
        .desc
          font-size 28px
        .right
          .immediate-play
            .iplay
              width 72px !important
              height 72px !important
            .order-play
              svg
                width 72px !important
                height 72px !important
    .init
      padding-bottom 200px
      width 100%
      height 100%
      overflow-y scroll
  .search-bottom
    position absolute
    bottom 50px
    left 80px
    width 854px
    height 346px
    img
      width 100%
      height 100%
    @media screen and (max-width 1200px) and (min-height 1200px)
      display none
  .search-headbar
    background transparent
  .search-bar-pure
    background transparent
    position fixed
    top 164px
    left 1014px
    width 826px
    padding 0
    // margin auto
    @media screen and (max-width 1200px) and (min-height 1200px)
      top 775px
      left 0
      right 0
      bottom 0
      width 100%
      padding 0 5vw
</style>
